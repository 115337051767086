import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

// export default styled.div`

export default styled(GatsbyLink)`
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 960px) {
    font-size: 22px;
  }
  background: white;
  border-color: none;
  border-style: none;
  border-width: none;
  border-radius: none;
  :hover {
    font-style: italic;
    color: black;
  }
  cursor: pointer;
`
